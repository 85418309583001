<template>
  <div v-if="ArrayList">
    <div v-for="item in List" :key="item.id">
      <slot name="item" :item="item"> </slot>
    </div>
    <div class="text-left ml-4" v-if="ArrayList.length > ShowNumberList">
      <a href="#" @click="boundedList = !boundedList">
        <span v-if="!boundedList"> Ver más </span>
        <span v-else> Ver menos </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ArrayList: {
      type: Array,
      required: true,
    },
    ShowNumberList: {
      type: Number,
      default: 3,
    },
    showIsActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      boundedList: this.showIsActive,
    };
  },
  computed: {
    List() {
      if (!this.ArrayList) return [];
      if (this.ArrayList.length < this.ShowNumberList + 1)
        return this.ArrayList;
      if (this.boundedList) return this.ArrayList;
      else {
        let list = [];
        for (let index = 0; index < this.ShowNumberList; index++) {
          list.push(this.ArrayList[index]);
        }
        return list;
      }
    },
  },
};
</script>

<style>
</style>